import { Col, Icon, Row, Spin, Upload, notification } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generateTemplateImportFinancialTransaction,
  importFinancialTransaction,
} from "../../../../services/importService";
import { Creators as AppActions } from "../../../../store/ducks/app";
import DrawerSupplier from "../../../drawers/supplier";
import ButtonStandard from "../../../utils/button";
import {
  ButtonWithoutBackground,
  Container,
  CustomModal,
  DownloadButton,
  Header,
  Title,
} from "./styles";
import { CurrencyContextProvider } from "../../../../contexts/currencyContext";
import SelectCurrency from "../../../utils/selectCurrency";
import { useCurrencyContext } from "../../../../hooks/useCurrencyContext";

const FinancialTransactionUploadModalLayout = () => {
  const [currencyType, setCurrencyType] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const props = {
    accept: ".xlsm, .xlsx",
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    showUploadList: false,
    fileList,
  };
  const { Dragger } = Upload;

  // Redux Variables
  const {
    translation,
    modalFinancialTransactionUploadVisible,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const { defaultCurrencyCode, farmCurrencies } = useCurrencyContext();

  const farmCurrenciesCodesFilter =
    farmCurrencies && farmCurrencies.length > 0
      ? farmCurrencies.map((fCurrency) => fCurrency.code)
      : [];

  // Methods

  const handleDownloadTemplate = async () => {
    setIsLoading(true);
    notification.info({
      message:
        "O seu template para importação está sendo gerado e logo será feito o download. Aguarde o download.",
    });
    try {
      const { data: result } = await generateTemplateImportFinancialTransaction(
        {
          groupId,
          farmId,
        }
      );
      const data = result;
      if (data != null) {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `BovExo_template_mov_financeira.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      notification.error({
        message: "Houve um erro ao gerar template. Contate o suporte.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsLoading(false);
    setFileList([]);
    dispatch(AppActions.hideModalFinancialTransactionUpload());
  };

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      const {
        data: { results },
      } = await importFinancialTransaction({
        groupId,
        farmId,
        currencyType: currencyType ?? "BRL",
        file: fileList[0],
      });
      if (results.numberUpdated > 0 && results.numberError === 0) {
        notification.success({
          message: "Movimentações financeiras importadas e atualizadas",
          description:
            'Houve algumas mov. financeiras que foram atualizadas/inseridas. Acesse o menu "Importações" aba "Mov. Financeiras" para ver os detalhes.',
          duration: 30,
        });
      } else if (results.numberImported > 0 && results.numberError === 0) {
        notification.success({
          message: "Movimentações financeiras importadas",
          duration: 30,
        });
      } else if (results.numberImported > 0 && results.numberError > 0) {
        notification.success({
          message: "Movimentações financeiras importadas parcialmente",
          description:
            'Houve algumas mov. financeiras que não foram importadas. Acesse o menu "Importações" aba "Mov. Financeiras" para ver os detalhes.',
          duration: 30,
        });
      } else if (results.numberImported === 0 && results.numberError > 0) {
        notification.error({
          message: "Arquivo processado com erro !",
          description:
            'Todos as mov. financeiras não foram importados. Acesse o menu "Importações" aba "Mov. Financeiras" para ver os detalhes.',
          duration: 0,
        });
      }
      handleCancel();
    } catch (error) {
      notification.error({
        message: "Erro ao importar",
        description: `Nenhuma mov. financeira foi incluído.`,
        duration: 0,
      });
      handleCancel();
    }
  };

  // Effects

  useEffect(() => {
    if (modalFinancialTransactionUploadVisible === true) {
      setCurrencyType(defaultCurrencyCode);
    }
  }, [defaultCurrencyCode, modalFinancialTransactionUploadVisible]);

  return (
    <CustomModal
      width={535}
      visible={modalFinancialTransactionUploadVisible}
      centered
      maskClosable={false}
      closable={false}
      footer={null}
    >
      <Container>
        <Spin spinning={isLoading}>
          <Header>
            <Title>{translation.importFinancialTransaction.title}</Title>
          </Header>

          {/* Message */}
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div
                dangerouslySetInnerHTML={{
                  __html: translation.importFinancialTransaction.message,
                }}
              />
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col span={24}>
              <p>
                <i>
                  Obs. Caso não seja informado um{" "}
                  <strong>N° de Documento</strong>, o sistema irá gerar um
                  número aleatório.
                </i>
              </p>
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col span={24}>
              <Row>
                <label htmlFor>Selecione a moeda da importação</label>
              </Row>
              <Row>
                <SelectCurrency
                  value={currencyType || undefined}
                  setValue={(code) => {
                    setCurrencyType(code);
                  }}
                  filterOptions={farmCurrenciesCodesFilter}
                />
              </Row>
            </Col>
          </Row>
          <Row type="flex" justify="end">
            <Col>
              <DownloadButton onClick={handleDownloadTemplate}>
                <Icon type="download" />
                {translation.buttons.download}
              </DownloadButton>
            </Col>
          </Row>

          {/* Upload area */}
          <Row type="flex" className="uploadRow">
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Dragger {...props}>
                  {fileList.length > 0 ? (
                    <Fragment>
                      <p className="ant-upload-drag-icon">
                        <Icon type="file-excel" />
                      </p>
                      <p className="ant-upload-text">{fileList[0].name}</p>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">
                        {translation.importFinancialTransaction.draggerMessage}
                      </p>
                    </Fragment>
                  )}
                </Dragger>
              </Col>
            </Row>

            <Row
              type="flex"
              className="footer"
              justify="space-between"
              align="middle"
            >
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <ButtonStandard
                  type="button"
                  buttonType="type4"
                  size="s"
                  onClick={handleCancel}
                >
                  {translation.buttons.cancel}
                </ButtonStandard>
              </Col>
              <Col xs={16} sm={16} md={16} lg={16} xl={16} align="right">
                <ButtonWithoutBackground
                  type="submit"
                  onClick={handleUpload}
                  disabled={fileList.length === 0}
                >
                  {translation.buttons.process}
                </ButtonWithoutBackground>
              </Col>
            </Row>
          </Row>
        </Spin>
      </Container>
      <DrawerSupplier />
    </CustomModal>
  );
};

const FinancialTransactionUploadModal = () => {
  return (
    <CurrencyContextProvider>
      <FinancialTransactionUploadModalLayout />
    </CurrencyContextProvider>
  );
};

export default FinancialTransactionUploadModal;
