import React, { Component } from "react";
import Axios from "axios";
import { Async } from "react-async";
import { withRouter, Link } from "react-router-dom";
import {
  Empty,
  Col,
  Menu,
  Icon,
  Dropdown,
  Row,
  Popover,
  Modal,
  notification,
  Tooltip,
  Tabs,
  Spin,
} from "antd";
import moment from "moment";
/* Redux libs */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as LotActions } from "../../../store/ducks/lot";
import { Creators as LotSupplementConsumptionActions } from "../../../store/ducks/lotSupplementConsumption";
import { Creators as DietStrategyActions } from "../../../store/ducks/dietStrategy";

/** Icons */
import Loading from "../../../components/utils/loading";

/** Styles Components */
import {
  Container,
  RowHeader,
  Title,
  TitleDate,
  ButtonOptions,
  CardCustomLotDetails,
  LabelGray,
  SpanBold,
  SpanItalic,
  BaselineTag,
  ButtonOptionalDiets,
  CardLotWeightAverage,
  CardLotPicketData,
  ListDietOptionals,
  AlertDefault,
} from "./styles";

/** Components */
import WeightIcon from "../../../components/utils/icons/weight";
import PicketIcon from "../../../components/admin/sidebar/icons/picket";
import LotDetailsCharts from "./charts";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import AlertIcon from "../../../components/drawers/alert/icons/alert";
import EyeIcon from "../../../components/utils/icons/eye";
import WarningIcon from "../../../components/utils/icons/warning";
import LotCostPieChart from "./lotCostPieChart";
import InfoTooltip from "../../../components/utils/infoTooltip";
import UserTriggerInProcessMessage from "../../../components/utils/userTriggerInProcessMessage";

/** Services */
import { getPicketShow } from "../../../services/picketService";
import {
  getLotShow,
  getLotStatisticsAsync,
  deleteInDatabaseAllAnimalsFromLot,
  getLotCosts,
} from "../../../services/lotService";
import { getDietStrategyIndex } from "../../../services/dietStrategyService";
import {
  getTwoDecimalDigits,
  numberMask,
  validateDietStrategyValidation,
} from "../../../services/helpersMethodsService";
import LotDetailsSupplementSupply from "./tabs/supplementSupply";
import { getParameterItems } from "../../../services/generalParameterService";

const CODE_PARAMETER_4002 = 4002;

class LotDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lotInfo: null,
      picketInfo: null,
      isLoading: false,
      isLoadingLotCost: false,
      isLoadingDeleteAnimals: false,
      showModalDeleteAnimals: false,
      deletedAllAnimals: false,
      useDietStrategyCost: true,
      activeTab: "1",
    };
  }

  signal = Axios.CancelToken.source();

  componentWillUnmount() {
    const { history, lotActions } = this.props;
    this.signal.cancel("Cancelando apis");
    if (
      history.location.pathname != null &&
      !history.location.pathname?.includes("lots")
    ) {
      lotActions.resetPaginationLotProduction();
      lotActions.resetData();
    }
  }

  async componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      const { id: groupId } = groupSelected;
      const { id: farmId } = farmSelected;
      const { id: lotId } = this.props.match.params;
      await Promise.all([
        this.getParameter4002(groupId, farmId),
        this.getLotInfo(groupId, farmId, lotId),
      ]);
      this.getLotCost(groupId, farmId, lotId);
    }
  }

  isAllowedAdmin = () => {
    const {
      user: { roles: userRoles },
    } = this.props.app;
    return userRoles?.includes("Admin") ? true : false;
  };

  getLotInfo = async (groupId, farmId, id) => {
    this.setState({ isLoading: true });
    try {
      const {
        data: { results: lotInfo },
      } = await getLotShow({ groupId, farmId, id, signal: this.signal });

      let lotDietStrategies = lotInfo?.dietStrategies;
      if (lotDietStrategies.length > 0) {
        const {
          data: { results: rAllDietStrategies },
        } = await getDietStrategyIndex({
          groupId,
          farmId,
          withoutPagination: true,
        });
        const dietStrategyBaseLine =
          lotDietStrategies.find((l) => l.baseline === true) != null
            ? lotDietStrategies.find((l) => l.baseline === true).dietStrategyId
            : null;
        lotDietStrategies = rAllDietStrategies
          .filter(
            (e) =>
              e.status === "Active" &&
              (e.lot?.id === lotInfo?.id || e.lot === null)
          )
          .map((l) => {
            if (l.id === dietStrategyBaseLine) {
              l.baseline = true;
            } else {
              l.baseline = false;
            }
            l.expired = validateDietStrategyValidation(l);
            return l;
          });
      }
      const lot = {
        ...lotInfo,
        dietStrategies: lotDietStrategies,
      };
      this.setState({ lotInfo: lot }, () => {
        this.getPicketInfo(groupId, farmId);
      });
    } catch (error) {
      console.error(error);
      this.setState({
        isLoading: false,
        lotInfo: null,
        picketInfo: null,
      });
    }
  };
  getLotCost = async (groupId, farmId, id) => {
    this.setState({ isLoadingLotCost: true });
    try {
      const {
        data: { results: lotCostInfo },
      } = await getLotCosts({ groupId, farmId, id, signal: this.signal });
      const lot = {
        ...this.state.lotInfo,
        lotCost: lotCostInfo,
      };
      this.setState({ lotInfo: lot, isLoadingLotCost: false });
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Erro ao pegar custos do lote. Contate o suporte.",
      });
      this.setState({
        isLoadingLotCost: false,
      });
    }
  };

  getPicketInfo = async (groupId, farmId) => {
    const { lotInfo } = this.state;
    if (lotInfo != null) {
      if (lotInfo?.picketId != null) {
        try {
          const {
            data: { results: picketInfo },
          } = await getPicketShow({
            groupId,
            farmId,
            id: lotInfo?.picketId,
            signal: this.signal,
          });
          this.setState({ isLoading: false, picketInfo });
        } catch (error) {
          this.setState({ isLoading: false, picketInfo: null });
        }
      }
    }
    this.setState({ isLoading: false });
  };

  getParameter4002 = async (groupId, farmId) => {
    const {
      data: { results: parameter4002 },
    } = await getParameterItems({
      groupId,
      farmId,
      code: CODE_PARAMETER_4002,
    });
    this.setState({
      useDietStrategyCost: parameter4002.value === "DietStrategy",
    });
  };

  showDietStrategyDetails = (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      dietStrategyActions,
    } = this.props;
    dietStrategyActions.showDietStrategy(groupId, farmId, id);
  };

  handleEdit = async (id) => {
    const {
      lotActions,
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    lotActions.showLot(groupId, farmId, id);
    lotActions.showDrawerLotProduction(id);
  };

  handleRefreshLotInfo = (data) => {
    this.setState({
      lotInfo: {
        ...this.state.lotInfo,
        ...data,
      },
    });
  };

  handleShowOrHideModal = () => {
    this.setState({
      showModalDeleteAnimals: !this.state.showModalDeleteAnimals,
    });
  };

  handleDeleteAllAnimals = async () => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    const { id: lotId } = this.props.match.params;
    const sig = this.signal;
    this.setState({
      isLoadingDeleteAnimals: true,
    });
    try {
      await deleteInDatabaseAllAnimalsFromLot({
        groupId,
        farmId,
        id: lotId,
        signal: sig,
      });
      notification.success({
        message: "Animais deletados com sucesso",
      });
      this.setState({
        isLoadingDeleteAnimals: false,
        showModalDeleteAnimals: false,
        deletedAllAnimals: true,
      });
      this.getLotInfo(groupId, farmId, lotId);
    } catch (error) {
      notification.error({
        message: "Erro ao deletar os animais",
      });
      this.setState({
        isLoadingDeleteAnimals: false,
        showModalDeleteAnimals: false,
      });
    }
  };

  handleShowLotSupplementConsumption = (lotId) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      lotSupplementConsumptionActions,
    } = this.props;
    lotSupplementConsumptionActions.showOrHideDrawerAndFetch(
      groupId,
      farmId,
      lotId
    );
  };

  handleTabChange = (key) => {
    this.setState({
      activeTab: key,
    });
  };

  getTotalCost = (lotCost) => {
    let dietCost = this.state.useDietStrategyCost
      ? lotCost?.sumDietCost
        ? lotCost.sumDietCostBySupplementSupply
        : 0
      : lotCost?.sumDietCostBySupplementSupply
      ? lotCost.sumDietCostBySupplementSupply
      : 0;

    let admCost = lotCost?.sumFixedVariableCost
      ? lotCost.sumFixedVariableCost
      : 0;

    let purchaseCost = lotCost?.sumPurchaseCost ? lotCost.sumPurchaseCost : 0;

    return numberMask(
      getTwoDecimalDigits(dietCost + admCost + purchaseCost),
      true
    );
  };

  getCurrentCost = (lotCost) => {
    let dietCost = this.state.useDietStrategyCost
      ? lotCost?.dietCost
        ? lotCost.dietCost
        : 0
      : lotCost?.dietCostBySupplementSupply
      ? lotCost.dietCostBySupplementSupply
      : 0;

    let admCost = lotCost?.fixedVariableCost ? lotCost.fixedVariableCost : 0;

    let purchaseCost = lotCost?.purchaseCost ? lotCost.purchaseCost : 0;

    return numberMask(
      getTwoDecimalDigits(dietCost + admCost + purchaseCost),
      true
    );
  };

  getProductionCost = (lotCost) => {
    let dietCost = this.state.useDietStrategyCost
      ? lotCost?.dietCost
        ? lotCost.dietCost
        : 0
      : lotCost?.dietCostBySupplementSupply
      ? lotCost.dietCostBySupplementSupply
      : 0;

    let admCost = lotCost?.fixedVariableCost ? lotCost.fixedVariableCost : 0;

    return numberMask(getTwoDecimalDigits(dietCost + admCost), true);
  };

  getDailyCost = (lotCost) => {
    let dailyDietCost = this.state.useDietStrategyCost
      ? lotCost?.dailyDietCost
        ? lotCost.dailyDietCost
        : 0
      : lotCost?.dailyDietCostBySupplementSupply
      ? lotCost.dailyDietCostBySupplementSupply
      : 0;

    let dailyAdmCost = lotCost?.dailyFixedVariableCost
      ? lotCost.dailyFixedVariableCost
      : 0;

    return numberMask(getTwoDecimalDigits(dailyDietCost + dailyAdmCost), true);
  };

  getDailyNutritionCost = (lotCost) => {
    let dailyDietCost = this.state.useDietStrategyCost
      ? lotCost?.dailyDietCost
        ? lotCost.dailyDietCost
        : 0
      : lotCost?.dailyDietCostBySupplementSupply
      ? lotCost.dailyDietCostBySupplementSupply
      : 0;

    return numberMask(getTwoDecimalDigits(dailyDietCost), true);
  };

  render() {
    const {
      app: {
        translation,
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      lot: { isLoading: lotLoading },
    } = this.props;

    const { id: lotId } = this.props.match.params;

    const {
      lotInfo,
      picketInfo,
      isLoading,
      showModalDeleteAnimals,
      isLoadingDeleteAnimals,
      deletedAllAnimals,
      activeTab,
    } = this.state;

    const getListDietOptionals = () => (
      <ListDietOptionals>
        {lotInfo != null &&
          lotInfo?.dietStrategies
            .filter((ds) => !ds.baseline)
            .map((ds2) => (
              <li key={ds2.id}>{`${ds2.name}${
                ds2.expired ? " (Vencido)" : ""
              }`}</li>
            ))}
      </ListDietOptionals>
    );

    const menu = (
      <Menu>
        <Menu.Item key="2">
          <Link to={`/admin/lots/${lotId}/add/animals`}>
            {translation.table.menu.addAnimalsToLotProduction}
          </Link>
        </Menu.Item>
        <Menu.Item
          key="0"
          onClick={() => this.handleShowLotSupplementConsumption(lotId)}
        >
          Consumo de Suplemento/Concent.
        </Menu.Item>
        {this.isAllowedAdmin() && (
          <Menu.Item key="3" onClick={this.handleShowOrHideModal}>
            Deletar todos os animais do lote
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={() => this.handleEdit(lotId)}>
          {translation.table.menu.edit}
        </Menu.Item>
      </Menu>
    );

    const dateFormat = "DD/MM/YYYY";

    return (
      <Container>
        {(isLoading === true || lotLoading === true) && <Loading />}
        <BreadCrumbs marginBottom="16px" marginTop="20px">
          <Row type="flex">
            <Col span={4}>
              <Link className="back-container" to="/admin/lots">
                <Icon type="left-circle" theme="filled" />{" "}
                {translation.buttons.back}
              </Link>
            </Col>
            <Col span={20} align="right">
              <Link to="/admin/lots">
                <span className="pageTreeInative">
                  {translation.lot.production.details.breadCumbsProductionLot}
                </span>
              </Link>
              <span className="pageTreeActive">
                {translation.lot.production.details.breadCumbsLotDetails}
              </span>
            </Col>
          </Row>
        </BreadCrumbs>
        <RowHeader type="flex" justify="space-between">
          <Col xs={16} sm={16} md={16} lg={16} xl={16}>
            <Title>
              {lotInfo?.name || "-"}
              <TitleDate>
                {`${translation.lot.production.details.createdAt} ${moment(
                  lotInfo?.createdAt
                ).format(dateFormat)}`}
              </TitleDate>
            </Title>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} align="right">
            <Dropdown overlay={menu} trigger={["click"]}>
              <ButtonOptions>
                {translation.lot.production.details.options}
                <Icon type="down" />
              </ButtonOptions>
            </Dropdown>
          </Col>
        </RowHeader>
        <Tabs
          type="card"
          activeKey={activeTab}
          onChange={(key) => this.handleTabChange(key)}
        >
          <Tabs.TabPane tab={<span>Detalhes</span>} key="1">
            <UserTriggerInProcessMessage triggerAction={"AnimalDailyCost"} />
            {/* Row with cards */}
            <Row type="flex" gutter={16}>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <CardCustomLotDetails>
                  <Row type="flex" justify="start" gutter={8}>
                    <Col span={6}>
                      <Row>
                        <LabelGray>
                          {translation.lot.production.details.labelSystem}
                        </LabelGray>
                      </Row>
                      <Row>
                        <SpanBold>{lotInfo?.profitCenterName || "-"}</SpanBold>
                      </Row>
                    </Col>
                    {lotInfo?.dietStrategies.length > 0 && (
                      <Col span={8}>
                        <Row>
                          <LabelGray>
                            {
                              translation.lot.production.details
                                .labelDietStrategy
                            }
                          </LabelGray>
                          <BaselineTag>
                            {translation.lot.production.details.baseline}
                          </BaselineTag>
                        </Row>
                        {lotInfo?.dietStrategies.find((ds) => ds.baseline) ? (
                          <Row type="flex">
                            <SpanBold>
                              {
                                lotInfo?.dietStrategies.find(
                                  (ds) => ds.baseline
                                ).name
                              }
                            </SpanBold>
                            <button
                              className="eyeButton"
                              onClick={() =>
                                this.showDietStrategyDetails(
                                  lotInfo?.dietStrategies.find(
                                    (ds) => ds.baseline
                                  ).id
                                )
                              }
                            >
                              <EyeIcon isActive />
                            </button>
                            {lotInfo?.dietStrategies.find((ds) => ds.baseline)
                              .expired === true && (
                              <Tooltip
                                title="Esta estratégia está vencida."
                                trigger="hover"
                              >
                                <div className="dietStrategyExpired">
                                  <AlertIcon />
                                </div>
                              </Tooltip>
                            )}
                          </Row>
                        ) : (
                          <Row type="flex">
                            <SpanItalic>Nenhum selecionado</SpanItalic>
                          </Row>
                        )}
                        {lotInfo?.dietStrategies.filter((ds) => !ds.baseline)
                          .length > 0 && (
                          <Row>
                            <Popover
                              title={
                                translation.lot.production.details
                                  .optionalStrategies
                              }
                              content={getListDietOptionals()}
                              trigger="click"
                              placement="bottom"
                            >
                              <ButtonOptionalDiets>
                                <Icon type="eye" />
                                {translation.lot.production.details.optional}
                              </ButtonOptionalDiets>
                            </Popover>
                          </Row>
                        )}
                      </Col>
                    )}
                    <Col span={4}>
                      <Row>
                        <LabelGray>
                          {translation.lot.production.details.labelAnimals}
                        </LabelGray>
                      </Row>
                      <Row>
                        {deletedAllAnimals ? (
                          <SpanBold>
                            <span>{`0 animais`}</span>
                          </SpanBold>
                        ) : (
                          <SpanBold>
                            <Async
                              promiseFn={getLotStatisticsAsync}
                              payload={{
                                groupId,
                                farmId,
                                id: lotId,
                                statistics: "AmountActiveAnimals",
                              }}
                            >
                              <Async.Pending>
                                <Icon type="loading" />
                              </Async.Pending>
                              <Async.Fulfilled>
                                {(resp) => (
                                  <span>{`${resp.data.results} animais`}</span>
                                )}
                              </Async.Fulfilled>
                            </Async>
                          </SpanBold>
                        )}
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Row type="flex" align="middle">
                        <LabelGray>
                          {
                            translation.lot.production.details
                              .labelAsymptoticWeight
                          }
                        </LabelGray>
                        {lotInfo?.customAsymptoticWeight === false && (
                          <Popover
                            title={null}
                            content="Peso assintótico obtida da RAÇA, caso deseje corrigir o valor acesse a edição de lote"
                            trigger="hover"
                            placement="top"
                          >
                            <AlertDefault />
                          </Popover>
                        )}
                      </Row>
                      <Row>
                        <SpanBold>{`${
                          lotInfo?.asymptoticWeight || 0
                        } kg`}</SpanBold>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start" gutter={8}>
                    <Col span={8}>
                      <Row>
                        <LabelGray>PEGADA CARBÔNICA ACUMULADA</LabelGray>
                      </Row>
                      <Row>
                        <Async
                          promiseFn={getLotStatisticsAsync}
                          payload={{
                            groupId,
                            farmId,
                            id: lotId,
                            statistics: "SumCarbonFootprintUntilToday",
                          }}
                        >
                          <Async.Pending>
                            <Icon type="loading" />
                          </Async.Pending>
                          <Async.Fulfilled>
                            {(resp) => (
                              <SpanBold>
                                {resp.data?.results != null
                                  ? ` ${numberMask(
                                      getTwoDecimalDigits(resp.data?.results),
                                      false
                                    )} `
                                  : `0 `}
                                CO<sub>2</sub>E / kg GDP
                              </SpanBold>
                            )}
                          </Async.Fulfilled>
                          <Async.Rejected>
                            <SpanBold>
                              0 kg CO<sub>2</sub>E / kg GDP
                            </SpanBold>
                          </Async.Rejected>
                        </Async>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row>
                        <LabelGray>PEGADA CARBÔNICA PREVISTA</LabelGray>
                      </Row>
                      <Row>
                        <Async
                          promiseFn={getLotStatisticsAsync}
                          payload={{
                            groupId,
                            farmId,
                            id: lotId,
                            statistics:
                              "SumCarbonFootprintUntilEndOfDietStrategy",
                          }}
                        >
                          <Async.Pending>
                            <Icon type="loading" />
                          </Async.Pending>
                          <Async.Fulfilled>
                            {(resp) => (
                              <SpanBold>
                                {resp.data?.results != null
                                  ? ` ${numberMask(
                                      getTwoDecimalDigits(resp.data?.results),
                                      false
                                    )} `
                                  : `0 `}
                                CO<sub>2</sub>E / kg GDP
                              </SpanBold>
                            )}
                          </Async.Fulfilled>
                          <Async.Rejected>
                            <SpanBold>
                              0 kg CO<sub>2</sub>E / kg GDP
                            </SpanBold>
                          </Async.Rejected>
                        </Async>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row>
                        <LabelGray>MÉDIA DE EMISSÃO DIÁRIA</LabelGray>
                      </Row>
                      <Row>
                        <Async
                          promiseFn={getLotStatisticsAsync}
                          payload={{
                            groupId,
                            farmId,
                            id: lotId,
                            statistics: "AverageCarbonFootprintUntilToday",
                          }}
                        >
                          <Async.Pending>
                            <Icon type="loading" />
                          </Async.Pending>
                          <Async.Fulfilled>
                            {(resp) => (
                              <SpanBold>
                                {resp.data?.results != null
                                  ? ` ${numberMask(
                                      getTwoDecimalDigits(resp.data?.results),
                                      false
                                    )} `
                                  : `0 `}
                                CO<sub>2</sub>E / kg GDP
                              </SpanBold>
                            )}
                          </Async.Fulfilled>
                          <Async.Rejected>
                            <SpanBold>
                              0 kg CO<sub>2</sub>E / kg GDP
                            </SpanBold>
                          </Async.Rejected>
                        </Async>
                      </Row>
                    </Col>
                  </Row>
                </CardCustomLotDetails>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Spin spinning={this.state.isLoadingLotCost}>
                  <CardCustomLotDetails className="productionCostsChart">
                    <Row type="flex" justify="start">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row type="flex" justify="start">
                          <LabelGray>
                            {
                              translation.lot.production.details
                                .labelProductionCost
                            }
                          </LabelGray>
                          <InfoTooltip
                            title={
                              <>
                                <span>
                                  Acompanhe os custos de produção dos animais
                                  presentes neste Lote.
                                </span>
                                <br />
                                <span>
                                  <b>Custo Atual:</b> Custo Total do animal até
                                  a data atual, considerando Valor de Aquisição
                                  + Nutrição + Custos Administ./Operac.
                                  (Custeio).
                                </span>
                                <br />
                                <span>
                                  <b>Custo de Produção:</b> Custo médio dos
                                  animais após entrada na fazenda, considerando
                                  Nutrição + Custos Administ./Operac. (Custeio).
                                </span>
                                <br />
                                <span>
                                  <b>Diária Atual</b>: Custo atual da diária de
                                  permanência, considerando Nutrição + Custos
                                  Administ./Operac. (Custeio).
                                </span>
                                <br />
                                <span>
                                  <b>Diária Alimentar:</b> Custo atual da diária
                                  referente a nutrição.
                                </span>
                              </>
                            }
                          />
                        </Row>
                        <Row type="flex" justify="start">
                          <Col span={16}>
                            <Row>
                              <LotCostPieChart
                                lotCost={
                                  lotInfo?.lotCost || {
                                    purchaseCost: 0,
                                    dietCost: 0,
                                    fixedVariableCost: 0,
                                  }
                                }
                              />
                            </Row>
                          </Col>
                          <Col span={8}>
                            <Row>
                              <span>
                                Atual:
                                <span className="bold">
                                  {this.getCurrentCost(lotInfo?.lotCost)}
                                </span>
                              </span>
                            </Row>
                            <Row>
                              <span>
                                Produção:
                                <span className="bold">
                                  {this.getProductionCost(lotInfo?.lotCost)}
                                </span>
                              </span>
                            </Row>
                            <Row>
                              <span>
                                Diária Atual:
                                <span className="bold">
                                  {this.getDailyCost(lotInfo?.lotCost)}
                                </span>
                              </span>
                            </Row>
                            <Row>
                              <span>
                                Diária Alim.:
                                <span className="bold">
                                  {this.getDailyNutritionCost(lotInfo?.lotCost)}
                                </span>
                              </span>
                            </Row>
                          </Col>
                        </Row>
                        <Row></Row>
                      </Col>
                    </Row>
                    <Row type="flex" justify="end">
                      <span>
                        Custo Total Lote:
                        <span className="bold">
                          {this.getTotalCost(lotInfo?.lotCost)}
                        </span>
                      </span>
                    </Row>
                  </CardCustomLotDetails>
                </Spin>
              </Col>
            </Row>
            {/* Row with CardLotWeightAverage and  */}
            <Row type="flex" gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                <CardLotWeightAverage type="flex" justify="start" gutter={2}>
                  <Col span={6}>
                    <span className="bold title">
                      <WeightIcon />
                      {translation.lot.production.details.labelWeights}
                    </span>
                  </Col>
                  <Col span={9}>
                    <Row>
                      <InfoTooltip title="Peso médio das últimas pesagens dos animais do lote." />
                      <span>
                        {translation.lot.production.details.labelAverageWeight}
                        <Async
                          promiseFn={getLotStatisticsAsync}
                          payload={{
                            groupId,
                            farmId,
                            id: lotId,
                            statistics: "AvgWeightActiveAnimals",
                          }}
                        >
                          <Async.Pending>
                            <Icon type="loading" />
                          </Async.Pending>
                          <Async.Fulfilled>
                            {(resp) => (
                              <span className="bold">
                                {resp.data?.results != null
                                  ? ` ${numberMask(
                                      getTwoDecimalDigits(resp.data?.results),
                                      false
                                    )} Kg`
                                  : `0 Kg`}
                              </span>
                            )}
                          </Async.Fulfilled>
                        </Async>
                      </span>
                    </Row>
                    <Row>
                      <InfoTooltip title="Peso médio das últimas pesagens ou pesos de acquisição (caso nenhum animal tenha pesagem) dos animais do lote, progredido até a data atual, utilizando a estratégia de dieta baseline(favorita)." />
                      <span>
                        PESO MÉDIO EST.:
                        <Async
                          promiseFn={getLotStatisticsAsync}
                          payload={{
                            groupId,
                            farmId,
                            id: lotId,
                            statistics: "EstimatedAvgWeightByDietStrategy",
                          }}
                        >
                          <Async.Pending>
                            <Icon type="loading" />
                          </Async.Pending>
                          <Async.Fulfilled>
                            {(resp) => (
                              <span className="bold">
                                {resp.data?.results != null
                                  ? ` ${numberMask(
                                      getTwoDecimalDigits(resp.data?.results),
                                      false
                                    )} Kg`
                                  : `0 Kg`}
                              </span>
                            )}
                          </Async.Fulfilled>
                        </Async>
                      </span>
                    </Row>
                    <Row>
                      <InfoTooltip title="Peso médio das últimas pesagens ou pesos de acquisição (caso nenhum animal tenha pesagem) dos animais do lote, progredido até a data atual, utilizando a curva do Lote." />
                      <span>
                        PESO MÉDIO EST. C.:
                        <Async
                          promiseFn={getLotStatisticsAsync}
                          payload={{
                            groupId,
                            farmId,
                            id: lotId,
                            statistics: "EstimatedAvgWeightByBreedCurve",
                          }}
                        >
                          <Async.Pending>
                            <Icon type="loading" />
                          </Async.Pending>
                          <Async.Fulfilled>
                            {(resp) => (
                              <span className="bold">
                                {resp.data?.results != null
                                  ? ` ${numberMask(
                                      getTwoDecimalDigits(resp.data?.results),
                                      false
                                    )} Kg`
                                  : `0 Kg`}
                              </span>
                            )}
                          </Async.Fulfilled>
                        </Async>
                      </span>
                    </Row>
                  </Col>
                  <Col span={9} align="right">
                    <InfoTooltip title="Ganho de peso médio das últimas pesagens dos animais do lote." />
                    <span>
                      {
                        translation.lot.production.details
                          .labelAverageWeightGain
                      }
                      <Async
                        promiseFn={getLotStatisticsAsync}
                        payload={{
                          groupId,
                          farmId,
                          id: lotId,
                          statistics: "AvgGMDActiveAnimals",
                        }}
                      >
                        <Async.Pending>
                          <Icon type="loading" />
                        </Async.Pending>
                        <Async.Fulfilled>
                          {(resp) => (
                            <span className="bold">
                              {resp.data?.results != null
                                ? ` ${resp.data?.results.toFixed(2)} Kg`
                                : `0 Kg`}
                            </span>
                          )}
                        </Async.Fulfilled>
                      </Async>
                    </span>
                  </Col>
                </CardLotWeightAverage>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                <CardLotPicketData
                  type="flex"
                  error={lotInfo?.picketName == null ? "true" : "false"}
                >
                  {/* Picket Title */}
                  <Col
                    xs={10}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    className="colPicketTitle"
                  >
                    <PicketIcon />
                    <span>
                      <span className="bold">
                        {translation.lot.production.details.labelPicket}
                      </span>

                      {lotInfo?.picketName != null ? (
                        ` ${lotInfo?.picketName}`
                      ) : (
                        <span>{` Não alocado`}</span>
                      )}
                    </span>
                  </Col>
                  {/* Picket extra data */}
                  <Col
                    xs={14}
                    sm={14}
                    md={14}
                    lg={14}
                    xl={14}
                    className="colPicketInfo"
                  >
                    {lotInfo?.picketName == null && (
                      <Tooltip
                        placement="left"
                        trigger="click"
                        title={
                          "Lote precisa estar alocado em um piquete / baia."
                        }
                      >
                        <div className="iconWarning">
                          <WarningIcon />
                        </div>
                      </Tooltip>
                    )}
                    {/* Picket Production SubSystem */}
                    <Row type="flex" justigy="start">
                      <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                        <span>
                          Subsistema:
                          <span className="bold">
                            {picketInfo?.productionSubSystem === "E"
                              ? " Extensivo"
                              : picketInfo?.productionSubSystem === "SI"
                              ? " Semi-Intensivo"
                              : picketInfo?.productionSubSystem === "I"
                              ? " Intensivo"
                              : " - "}
                          </span>
                        </span>
                      </Col>
                    </Row>
                    {/* Picket pastureArea and totalTroughSpace */}
                    <Row type="flex" justify="space-between" align="middle">
                      <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                        <span>
                          Área de pastagem:
                          <span className="bold">{` ${
                            picketInfo?.pastureArea || 0
                          } ha`}</span>
                        </span>
                      </Col>
                      <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                        <span>
                          Cocho:
                          <span className="bold">{` ${
                            picketInfo?.totalTroughSpace || 0
                          } cm`}</span>
                        </span>
                      </Col>
                    </Row>
                    {/* Picket  pastureAnimalCapacity and drinkingFountain*/}
                    <Row type="flex" justify="space-between" align="middle">
                      <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                        <span>
                          Lotação:
                          <span className="bold">{` ${
                            picketInfo?.pastureAnimalCapacity || 0
                          } ${
                            picketInfo?.productionSubSystem === "I" ||
                            picketInfo?.productionSubSystem === "Intensivo"
                              ? "CAB"
                              : "UA/ha"
                          }`}</span>
                        </span>
                      </Col>
                      <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                        <span>
                          Bebedouro:
                          <span className="bold">{` ${
                            picketInfo?.drinkingFountain || 0
                          } cm`}</span>
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </CardLotPicketData>
              </Col>
            </Row>
            {/* LotDetailsCharts Chart */}
            {deletedAllAnimals ? (
              <Empty
                description="Este lote não possui animais"
                style={{ marginTop: 40 }}
              ></Empty>
            ) : (
              <Async
                promiseFn={getLotStatisticsAsync}
                payload={{
                  groupId,
                  farmId,
                  id: lotId,
                  statistics: "AmountActiveAnimals",
                }}
              >
                <Async.Fulfilled>
                  {(resp) =>
                    resp.data.results > 0 ? (
                      <LotDetailsCharts
                        lotId={lotId}
                        refreshLotInfo={this.handleRefreshLotInfo}
                      />
                    ) : (
                      <Empty
                        description="Este lote não possui animais"
                        style={{ marginTop: 40 }}
                      ></Empty>
                    )
                  }
                </Async.Fulfilled>
              </Async>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span>Fornecimento</span>} key="2">
            <LotDetailsSupplementSupply />
          </Tabs.TabPane>
        </Tabs>
        {/* Drawers */}
        {/* Modals */}
        <Modal
          visible={showModalDeleteAnimals}
          onOk={this.handleDeleteAllAnimals}
          confirmLoading={isLoadingDeleteAnimals}
          onCancel={this.handleShowOrHideModal}
        >
          <span>
            <Icon type="question-circle" style={{ color: "#FAAD15" }} />
            {` Você realmente quer deletar todos os animais ? `}
          </span>
        </Modal>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  app: state.app,
  lot: state.lot,
});

const mapDispatchToProps = (dispatch) => ({
  lotActions: bindActionCreators(LotActions, dispatch),
  lotSupplementConsumptionActions: bindActionCreators(
    LotSupplementConsumptionActions,
    dispatch
  ),
  dietStrategyActions: bindActionCreators(DietStrategyActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LotDetails)
);
